import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getListQuanter } from "../../../helpers/getListQuanter";
import { TableChartWrapper, ItemQuarter } from "./styled";

const IndicatorsContainer = () => {
  const { t } = useTranslation();
  const timeLine = getListQuanter(t("indicatior.quarter")).reverse();
  const dashboardData = useSelector((state) => state?.data?.dashboardData);

  const getData = (quanter, index) => {
    if (!dashboardData) {
      return null;
    }
    const response = dashboardData[quanter]?.data?.main[index + 1]?.result;
    if (response === -1) {
      return "N/A";
    }
    if (typeof response === "undefined") {
      return "N/A";
    }
    return response;
  };
  const getDataSub2 = (quanter, index) => {
    if (!dashboardData) {
      return null;
    }
    const response = dashboardData[quanter]?.data?.main[index]?.result;
    if (response === -1) {
      return "N/A";
    }
    if (typeof response === "undefined") {
      return "N/A";
    }
    return response;
  };

  const checkIsNumber = (props) => {
    if (props === "N/A") {
      return props;
    }
    return `${props}%`;
  };

  const dataTable = [
    {
      hospital_name: t("provinceData.data_1"),
      hospital_data: [4, 5, 6, 7],
      functionColor: (number) => {
        if (number === "N/A") return "#bdc3c7";
        let color = "#2ecc71";
        if (number < 100) {
          color = "#e74c3c";
        }
        return color;
      },
    },
    {
      hospital_name: t("provinceData.data_2"),
      hospital_data: [80, 90, 70, 40],
      functionColor: (number) => {
        if (number === "N/A") return "#bdc3c7";
        let color = "#2ecc71";
        if (number >= 50 && number < 80) {
          color = "#f1c40f";
        }
        if (number < 50 || !number) {
          color = "#e74c3c";
        }
        return color;
      },
    },
    {
      hospital_name: t("provinceData.data_3"),
      hospital_data: [100, 80, 70, 50],
      functionColor: (number) => {
        if (number === "N/A") return "#bdc3c7";
        let color = "#2ecc71";
        if (number >= 60 && number < 80) {
          color = "#f1c40f";
        }
        if (number < 60 || !number) {
          color = "#e74c3c";
        }
        return color;
      },
    },
    {
      hospital_name: t("provinceData.data_4"),
      hospital_data: [100, 80, 70, 40],
      functionColor: (number) => {
        if (number === "N/A") return "#bdc3c7";
        let color = "#2ecc71";
        if (number < 100) {
          color = "#e74c3c";
        }
        return color;
      },
    },
    {
      hospital_name: t("provinceData.data_5"),
      hospital_data: [100, 80, 70, 59],
      functionColor: (number) => {
        if (number === "N/A") return "#bdc3c7";
        let color = "#2ecc71";
        if (number >= 60 && number < 80) {
          color = "#f1c40f";
        }
        if (number < 60 || !number) {
          color = "#e74c3c";
        }
        return color;
      },
    },
    {
      hospital_name: t("provinceData.data_6"),
      hospital_data: [100, 80, 70, 59],
      functionColor: (number) => {
        if (number === "N/A") return "#bdc3c7";
        let color = "#2ecc71";
        if (number >= 60 && number < 80) {
          color = "#f1c40f";
        }
        if (number < 60 || !number) {
          color = "#e74c3c";
        }
        return color;
      },
    },
    {
      hospital_name: t("provinceData.data_7"),
      hospital_data: [100, 80, 70, 59],
      functionColor: (number) => {
        if (number === "N/A") return "#bdc3c7";
        let color = "#2ecc71";
        if (number >= 60 && number < 80) {
          color = "#f1c40f";
        }
        if (number < 60 || !number) {
          color = "#e74c3c";
        }
        return color;
      },
    },
    {
      hospital_name: t("provinceData.data_8"),
      hospital_data: [100, 80, 70, 59],
      functionColor: (number) => {
        if (number === "N/A") return "#bdc3c7";
        let color = "#2ecc71";
        if (number >= 60 && number < 80) {
          color = "#f1c40f";
        }
        if (number < 60 || !number) {
          color = "#e74c3c";
        }
        return color;
      },
    },
    {
      hospital_name: t("provinceData.data_9"),
      hospital_data: [100, 80, 70, 59],
      functionColor: (number) => {
        if (number === "N/A") return "#bdc3c7";
        let color = "#2ecc71";
        if (number >= 60 && number < 80) {
          color = "#f1c40f";
        }
        if (number < 60 || !number) {
          color = "#e74c3c";
        }
        return color;
      },
    },
    {
      hospital_name: t("provinceData.data_10"),
      hospital_data: [100, 80, 70, 59],
      functionColor: (number) => {
        if (number === "N/A") return "#bdc3c7";
        let color = "#2ecc71";
        if (number >= 60 && number < 90) {
          color = "#f1c40f";
        }
        if (number < 60 || !number) {
          color = "#e74c3c";
        }
        return color;
      },
    },
  ];
  return (
    <TableChartWrapper>
      <div className="header-chart background-color">
        <div className="header-index">{t("indicatior.index")}</div>
        <div className="criteria">{t("exportData.indexHospitals")}</div>
        {timeLine?.slice(-4).map((element, indexTime) => (
          <div className={`quarter ${indexTime === 3 && "border-right-none"}`}>
            {element}
          </div>
        ))}
      </div>

      <div>
        {dataTable?.map((hospital, indexSub) => (
          <React.Fragment key={String(indexSub)}>
            <div className="header-chart border-top-none">
              <div className="header-index">{indexSub + 1}</div>
              <div className="criteria title-content">
                {hospital.hospital_name}
              </div>
              {[4, 5, 6, 7].map((dataHostPital, indexDataHostpital) => (
                <React.Fragment key={String(indexDataHostpital)}>
                  <ItemQuarter
                    color={hospital?.functionColor(
                      getData(dataHostPital, indexSub)
                    )}
                    key={String(indexDataHostpital)}
                    className={`quarter ${
                      indexDataHostpital === 3 && "border-right-none"
                    }`}
                  >
                    {checkIsNumber(getData(dataHostPital, indexSub))}
                  </ItemQuarter>
                </React.Fragment>
              ))}
            </div>
            {indexSub === 1 && (
              <div className="header-chart border-top-none">
                <div className="header-index">2.1</div>
                <div className="criteria title-content">
                  {t("provinceData.data_2_1")}
                </div>
                {[4, 5, 6, 7].map((dataHostPital, indexDataHostpital) => (
                  <React.Fragment key={String(indexDataHostpital)}>
                    <ItemQuarter
                      color={hospital?.functionColor(
                        getDataSub2(dataHostPital, "2_1")
                      )}
                      key={String(indexDataHostpital)}
                      className={`quarter ${
                        indexDataHostpital === 3 && "border-right-none"
                      }`}
                    >
                      {checkIsNumber(getDataSub2(dataHostPital, "2_1"))}
                    </ItemQuarter>
                  </React.Fragment>
                ))}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </TableChartWrapper>
  );
};

export default IndicatorsContainer;
