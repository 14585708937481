import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import LinePointLaos from "../../common/line-chart/LinePointLaos";
import {
  ItemWrapper,
  LineChartContainerWrapper,
} from "./lineChartContainerStyle";

const LineChartContainer = () => {
  const dashboardData = useSelector((state) => state?.data?.dashboardData);
  const listLabel = [
    "Prolonged Skin to Skin contact and Early Initiation of Breastfeeding (EIBF) before separation",
    "Exclusive breastfeeding (EBF) during hospital stay",
    "Mother with basic knowledge in terms of breastfeeding after receiving education/counseling",
    "Mother receiving breastfeeding screening before discharge",
  ];

  const getDatafromIndex = (indexItem) => {
    if (!dashboardData) {
      return null;
    }
    const dataGet = [0, 1, 2, 3, 4, 5, 6, 7]?.map((element) => {
      const resElement = dashboardData[element]?.data?.main[indexItem]?.result;
      if (resElement === -1) {
        return null;
      }
      return resElement;
    });
    return dataGet;
  };

  const dataChartLine = useMemo(() => {
    const data1 = getDatafromIndex(2);
    const data2 = getDatafromIndex(3);
    const data3 = getDatafromIndex(7);
    const data4 = getDatafromIndex(9);
    return [data1, data2, data3, data4];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);
  return (
    <LineChartContainerWrapper>
      {/* {listLabel?.map((element, index) => {
        return ( */}
      <ItemWrapper>
        {/* <h2>{element}</h2> */}
        <LinePointLaos data1={dataChartLine[1]} data={dataChartLine} />
      </ItemWrapper>
      {/* })} */}
    </LineChartContainerWrapper>
  );
};

export default LineChartContainer;
